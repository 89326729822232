.container{
  width: 100%;
  margin: 0 auto;
  max-width: 1280px;
  padding: 20px 20px 50px;
  &.user{
    display: grid;
    grid-gap:20px;
    grid-template-rows: minmax(0, auto) auto;
    grid-template-columns: calc(100vw - 40px);
    grid-template-areas: "sidebar" "main";
    @include for-size(phone, up){
      grid-gap: 40px;
      grid-template-rows: auto;
      grid-template-columns: 1fr 255px;
      grid-template-areas: "main sidebar";
    }
    main{
      grid-area: main;
    }
    .sidebar{
      grid-area: sidebar;
      .btn{
        width: 100%;
        margin: 0 0 10px;
      }
    }
  }
  // &.admin {
    
  // }
}