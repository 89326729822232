button, .btn, input[type="submit"]{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

.btn{
  display: inline-block;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 20px;
  min-width: 250px;
  font-size: 20px;
  font-weight: bold;
  font-family: $primary-font; 
  border: none;
  color: white;
  text-decoration: none;
  white-space: nowrap;
  outline: none;
  &:hover{
    cursor: pointer;
  }
  backface-visibility: hidden;
  &.green{
    background: $green;
    &:hover{
      background: darken($green, 5%);
    }
  }
  &.grey{
    background: $grey;
    &:hover{
      background: darken($grey, 5%);
    }
  }
  & + &{
    @include for-size(phone, up){
      margin-left: 20px;
    }
  }
}