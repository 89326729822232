html, body {
  font-family: $primary-font; 
  @media 
  (-webkit-min-device-pixel-ratio: 2), 
  (min-resolution: 192dpi) { 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: greyscale;
  }  
}

h1, h2, h3, h4, h5, h6 {
  font-family: $secondary-font;
  font-weight: 500;  
}