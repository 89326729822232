form{
  @include for-size(phone, up){
    max-width: 600px;
  }

  ::placeholder,
  :-ms-input-placeholder,
  ::-ms-input-placeholder
   { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $darkGrey;
    opacity: 1; /* Firefox */
  }

  input[type='text'],
  input[type='password'],
  .select,
  textarea{
    width: 100%;
    border: none;
    font-size: 20px;
    padding: 10px 15px;
    border: 1px solid $grey;
    font-family: $primary-font; 
    margin: 5px 0;
  }

  textarea{
    min-height: 150px;
    & + small{
      font-style: oblique;
      color: $darkGrey;
      display: block;
      padding: 0 15px;
      margin-bottom: 10px;
    }
  }

  .select{
    -moz-appearance: none;
    -webkit-appearance: none;
    border-radius: 0;
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-down' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-angle-down fa-w-10 fa-2x'%3E%3Cpath fill='%23999' d='M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z' class=''%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat, repeat;
    background-position: right 1em top 50%, 0 0;
    background-size: .8em auto, 100%;
  }

  select{
    &:not(:focus):invalid{
      color: $darkGrey;
    }
  }

  // Custom width for react-datepicker
  .react-datepicker{
    &__input-container {
      width: inherit;
    }
    &-wrapper {
      width: 100%;
    }
  }

  .btn{
    margin: 10px 0;
    @include for-size(phone, down){
      width: 100%;
      display: block;
    }
  }
}