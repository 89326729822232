.status{
  &__wrapper{
    margin: 20px 0;
    display: grid;
    grid-gap: 20px;
    grid-template-rows: auto auto;
    grid-template-areas: "status" "actions";
    &:first-of-type{
      margin-top: 0;
    }
    @include for-size(phone, up){
      grid-template-rows: auto;
      grid-template-columns: 1fr 150px;
      grid-template-areas: "status actions";
    }
    nav{

      grid-area: actions;
      @include for-size(phone, up){
        justify-self: end;
      }
      &.hide{
        display: none;
      }
    }
  }
  &__content{
    grid-area: status
  }
  &__time,
  &__date,
  &__status,
  &__push-back,
  &__notes{
    display: block;
  }

  &__date,
  &__status{
    font-weight: bold;
  }

  &__time{
    color: $darkGrey;
    font-style: oblique;
    font-size: 14px;
  }

  &__action{
    font-family: $secondary-font;
    background: transparent;
    border: none;
    color: $green;
    display: inline-block;
    padding: 0 10px;
    border-left: 1px solid $green;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    outline: 0;
    &:hover{
      cursor: pointer;
      color: darken($green, 5%);
    }
    &:first-child{
      padding-left: 0;
      border: none;
    }
  }
  
}