.table-row{
  display: grid;
  grid-template-columns: 120px 2fr 1fr 1fr 20px 20px;
  gap: 20px;
}
.admin-job-table{
  &__wrapper{
    padding: 20px 0;
  }
  &__listing{
    @extend .table-row;
    padding: 20px 0;
    border-bottom: 1px solid $grey;
    .action-btn{
      display: inline-block;
      background: none;
      border: 0;
      padding: 0px;
      outline: 0;
      .icon{
        height: 17px;
        fill: $darkGrey;
      }
      &:hover{
        cursor: pointer;
        .icon{
          fill: $green;
        }
      }
    }
    &:last-of-type{
      border-bottom: none;
    }
  }
  &__header{
    @extend .table-row;
    span{
      text-transform: uppercase;
      color: $green;
      font-family: $secondary-font;
      font-size: 20px;
    }
  }
}

.admin-user-table{
  &__wrapper{
    @extend .admin-job-table__wrapper;
  }
  &__listing{
    @extend .admin-job-table__listing;
  }
  &__header{
    @extend .admin-job-table__header;
  }
  &__listing,
  &__header{
    grid-template-columns: 1fr 1fr 1fr 20px 20px;
  }

}



