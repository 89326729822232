.job{
  &__wrapper{
    border-bottom: 1px solid $grey;
    &:last-of-type{
      border-bottom: 0;
      margin-bottom: 40px;
    }
  }
  &__header{
    font-size: 16px;
    line-height: 22px;
    padding: 20px 0;
    position: relative;
    .toggle-btn{
      position: absolute;
      background: transparent;
      width: 100%;
      padding: 0;
      top:0;
      right: 0;
      bottom: 0;
      right: 0;
      border: 0;
      outline: none;
      &:hover{
        cursor: pointer;
      }
      .icon{
        position: absolute;
        width: 19px;
        will-change: transform; 
        bottom:20px;
        right:0;
        transition: transform 0.3s $nice-bezier;
        fill: $green;
      }
    }
    .open &{
      .icon{
        transform: rotate(180deg);
      }
    }
  }
  &__title{
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 16px;
    margin: 0;
    span{
      font-weight: normal;
    }
  }
  &__city,
  &__foreman{
    display: block;
  }
}

.statuses{
  display: none;
  .open &{
    display: block;
  }
  .heading{
    font-size: 20px;
    font-weight: normal;
    color: $green;
    text-transform: uppercase;
    margin: 0;
  }
}