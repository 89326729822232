.login{
  &__wrapper{
    grid-area: nav;
    justify-self: end;
    position: relative;
    width: 100px;
    display: grid;
    .login-out-btn{
      text-align: center;
      width: 100px;
      background: transparent;
      border: none;
      color: white;
      font-size: 12px;
      font-weight: bold;
      outline: none;
      text-transform: uppercase;
      padding: 0;
      &:hover{
        cursor: pointer;
      }
    }
    &.active{
      background: $green;
    }
  }
  &__form{
    display: none;
    position: absolute;
    padding:20px;
    top: 74px;
    right: 0px;
    z-index: 2;
    width: 100vw;
    background: $green;
    @include for-size(phone, up){
      top: 111px;
      width: 375px;
    }
    .active &{
      display:block;
    }
    input[type=text],
    input[type=password]{
      width: 100%;
      margin: 6px auto;
      border: transparent;
      &:last-of-type{
        margin-bottom: none;
      }
    }
    .btn{
      width: 100%;
      margin: 6px auto;
      border: 2px solid white;
    }
    a{
      display: block;
      font-size: 12px;
      color: white;
      text-align: right;
    }
  }
  &__shade{
    display: none;
    background: transparent;
    position: absolute;
    width: 100vw;
    top: 0;
    bottom: 0;
    border: 0;
    z-index: 1;
    &.active{
      display: block;
    }
  }
}