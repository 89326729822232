.header{
  background: url('../../images/header-bg.png') top center no-repeat;
  background-size: cover;
  position: auto;
  z-index: 2;
}

.logo{
  display: inline-block;
  width: 255px;
  height: 52px;
  grid-area: logo;
  img{
    width:100%;
  }
  @include for-size(phone, up){
    width: 375px;
    height: 76px;
  }
  &__wrapper{
    padding: 20px 0;
    background: rgba(white, .8);
    display: grid;
    grid-template-columns: minmax(20px, 1fr) minmax(255px, 1240px) minmax(20px, 1fr);
    grid-template-areas: ". logo .";
    @include for-size(phone, up){
      padding: 35px 0;
    }
  }
}

.nav{
  background: rgba(black, .7);
  color: white;
  display: grid;
  grid-template-rows: 74px;
  grid-template-columns: minmax(20px, 1fr) minmax(255px, calc(100vw - 20px));
  grid-template-areas: ". nav";
  @include for-size(phone, up){
    grid-template-columns: minmax(20px, 1fr) minmax(255px, 1240px) minmax(20px, 1fr);
    grid-template-areas: ". nav .";
    grid-template-rows: 111px;
  }
  .page-title{
    margin: 0;
    grid-area: nav;
    font-size: 30px;
    align-self: center;
    @include for-size(phone, up){
      font-size: 36px;
    }
  }

  
}