.modal{
  &__wrapper{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content{
    position: relative;
    z-index: 10;
    margin: 20px 20px 100px;
  }

  &__close{
    border: none;
    background:transparent;
    position: absolute;
    padding: 10px;
    top: 5px;
    right: 0;
    outline: none;
    &:hover{
      cursor: pointer;
      .icon{
        fill: darken($grey, 20%)
      }
    }
    .icon{
      width: 20px;
      fill: $grey;
    }
  }

  &__card{
    padding: 10px 30px 30px;
    background-color: white;	
    box-shadow: 0 10px 25px -10px rgba(0,0,0,0.5);
    .heading{
      color: $green;
      font-weight: normal;
      text-transform: uppercase;
      font-family: $secondary-font;
    }
    p{
      &:last-of-type{
        margin-bottom: 30px;
      }
    }
    @include for-size(phone, down){
      display: inline-block;
      text-align: center;
      .btn{
        display: block;
        margin-top:20px;
      }
    }
  }

  &__background{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.8;
  }
}