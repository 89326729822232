// Media Query Mixin
@mixin for-size($breakpoint, $direction:down) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
  
  // Get the breakpoint value.
  $breakpoint-value: map-get($breakpoints, $breakpoint);
  
  // Determine the direction and then write the media query.
  @if $direction == up {
    @media (min-width: $breakpoint-value) {
      @content;
    }
  }
  @else if $direction == down {
    @media (max-width: $breakpoint-value) {
      @content;
       }
    }
  }
  // If the breakpoint doesn't exist in the map, pass a manual breakpoint
  @else {
    @if $direction == up {
      @media (min-width: $breakpoint) {
        @content;
      }
    }
    @else if $direction == down {
      @media (max-width: $breakpoint) {
        @content;
        }
      }
    }
  }