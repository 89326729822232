$green: #80BD01;
$grey: #C7C6C7;
$darkGrey: #9B9B9B;

$primary-font: 'Open Sans', sans-serif;
$secondary-font: 'Oswald', sans-serif;

$breakpoints: (
  phone-sm: 420px,
  phone: 767px,
  tablet: 768px,
  tablet-lg: 1024px,
  desktop: 1025px,
  desktop-lg: 1600px
);

$nice-bezier: cubic-bezier(0.165, 0.84, 0.44, 1);